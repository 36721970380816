import { NitroFetchOptions, NitroFetchRequest } from "nitropack";

export const useApi: any = async (
  url: string,
  options: NitroFetchOptions<NitroFetchRequest> & { noVia?: boolean } = {}
) => {
  const { headers, method, body, noVia, ...rest } = options;

  const config = useRuntimeConfig();
  const errors = useErrors();
  const user = useAuthUser();

  return await $fetch(url, {
    method: method || "GET",
    baseURL: config.public.apiBaseUrl,
    headers: {
      // If not Form-data append application/json headers
      ...(body instanceof FormData
        ? {}
        : { Accept: "application/json", "Content-Type": "application/json" }),
      ...(headers || {}),
      // If authenticated append Authorization header
      ...(user && user.value && user.value.access_token
        ? {
            Authorization: "Bearer " + user.value.access_token,
          }
        : {}),
    },
    body: body || null,
    ...rest,

    async onRequest({ request, options }) {
      options.params = options.params || {};
      if (!noVia) {
        options.params.__via = "offline_form";
      }
      errors.value = {};
    },

    async onResponse({ request, response, options }) {
      // USE ONLY IF SUCCESS (ERROR HANDLED BY BELOW onResponseError OPTION)
      if (response.ok) {
        if (
          response._data &&
          (response._data.message || response._data.detail)
        ) {
          useToast().success(response._data.message || response._data.detail);
        }
      }
    },

    async onResponseError({ request, response, options }) {
      if (response._data && (response._data.message || response._data.detail)) {
        useToast().error(response._data.message || response._data.detail);

        errors.value = {
          message: response._data.message || response._data.detail,
          errors: response._data.errors || [],
        };
      }

      // UN-AUTHORIZED TRIGGERS A LOGOUT
      if (response.status === 401) {
        useToast().error("Unauthenticated Request");

        let { logout } = useLogin();
        // Logout, BUT only redirect to login if app is not in guest mode.
        await logout(isAppInGuestMode() ? false : true);
      }
    },
  }).catch((error) => {
    // If not already filled (generally due to network or local error)
    if (!errors.value || !errors.value.message) {
      errors.value = { message: "Something went wrong." };
    }
  });
};
