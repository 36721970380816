import { db } from "~~/db";

export interface User {
  id: string | number;
  name: string;
  email: string;
  role: string;
  branch_id?: string | number;
  branch?: {
    id: string | number;
    name: string;
  };
}

export interface LoginResponse {
  id?: string | number;
  email?: string;
  message?: string;
  access_token?: string;
  data?: User;
}

export const useLogin = () => {
  const authUser = useAuthUser();

  const login = async (email: string) => {
    const loginResponse: LoginResponse = await useApi("/login", {
      method: "POST",
      body: {
        email: email,
        url: window.location.origin,
      },
    });

    if (loginResponse.email && loginResponse.message) {
      return loginResponse;
    } else if (loginResponse.message) {
      useErrors().value = { message: loginResponse.message as string };
    }

    return null;
  };

  const logout = async (redirectToLoginPage = true) => {
    authUser.value = null; // Logout a user by clearing local storage

    useForms().value = []; // Clear all forms
    await db.forms.clear(); // Clear all forms from indexedDB

    if (redirectToLoginPage) {
      await navigateTo({ path: "/" });
    }
  };

  const user = () => {
    return authUser;
  };

  const loggedIn = () => {
    return authUser.value?.id ? true : false;
  };

  const fetchUser = async () => {
    if (authUser.value?.access_token) {
      const userResponse: User = await useApi("/user");
      if (userResponse) {
        authUser.value = { ...authUser.value, data: userResponse };
      }
    }

    return authUser?.value?.data || null;
  };

  const setToken = (token: string) => {
    authUser.value = { ...authUser.value, access_token: token };
  };

  return {
    login,
    logout,
    get user() {
      return user();
    },
    loggedIn,
    fetchUser,
    setToken,
  };
};
