import Dexie, { Table } from "dexie";
import {
  FileHistoryType,
  FormsTypes,
  OfflineFileStorageType,
  PendingSubmissionTypes,
  TranslationsTypes,
} from "~~/types/forms";

export const offlineDatabaseName = "offline-database";
export class NewDexie extends Dexie {
  forms!: Table<FormsTypes>;
  translations!: Table<TranslationsTypes> & { id: number };
  submissions!: Table<PendingSubmissionTypes>;
  files_history!: Table<FileHistoryType>;

  constructor() {
    super(offlineDatabaseName);

    const title = useRuntimeConfig().public.title;

    // Don't forget to update the version number when you make changes to the database schema.
    this.version(3)
      .stores({
        forms: "id, name",
        translations: "++id, language",
        submissions: "++id, form_name",
        files_history: "++id, indexed_id, first_seen_at, is_compressed, type",
      })
      .upgrade((transaction) => {
        alert(
          `Database Upgraded.\nPlease refresh the page as soon as possible.\n\n- ${title}`
        );
      });
  }
}

export const db = new NewDexie();

export const offlineDatabaseFileStorageName = "offline-files-database";
export const offlineFileIndexedDbTableName = "files";
export class FileStorageDatabaseDexie extends Dexie {
  files!: Table<OfflineFileStorageType>;

  constructor() {
    super(offlineDatabaseFileStorageName);

    // dexie will generally multiple provided version 0.3 by 10 = giving 3
    // We need version 3 because, form.io js is hardcoded to use version 3
    // formio.js/src/providers/storage/indexeddb.js
    this.version(0.3).stores({
      files: ",id, name",
    });
  }
}

export const filesDb = new FileStorageDatabaseDexie();
